/* istanbul ignore file: test forms*/
import React, {ComponentType, createContext, useContext, useRef, useState} from 'react';
import {FormHandle, FormValues} from '@wix/form-viewer';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {FormViewerHandle} from '@wix/form-viewer/widget';
import {FullAddressContactDetails} from '@wix/ambassador-ecom-v1-checkout/types';
import {
  getContactDetailsFromContactFormValues,
  getContactFormInitialState,
} from '../../../../../../domain/utils/contactDetails.utils';
import {CashierMandatoryFieldsOverrides} from '../../../../../../types/app.types';

export type BillingDataContextType = {
  setContactFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  contactFormValues: FormValues;
  contactFormViewer: React.RefObject<FormHandle>;
  isFormValid: () => Promise<boolean>;
  initForm: () => void;
  getContactDetailsFormValues: () => FullAddressContactDetails;
  cashierMandatoryFields: CashierMandatoryFieldsOverrides;
  setCashierMandatoryFields: React.Dispatch<React.SetStateAction<CashierMandatoryFieldsOverrides>>;
};

export const BillingDataContext = createContext({} as BillingDataContextType);

export function withBillingData<T extends object>(Component: ComponentType<T>) {
  return function Wrapper(props: T) {
    const {
      checkoutStore: {checkout},
      checkoutSettingsStore: {checkoutSettings},
    } = useControllerProps();

    const [contactFormValues, setContactFormValues] = useState<FormValues>(
      getContactFormInitialState(checkoutSettings, checkout.billingInfo?.contact)
    );

    const [cashierMandatoryFields, setCashierMandatoryFields] = useState<CashierMandatoryFieldsOverrides>({});

    const contactFormViewer = useRef<FormViewerHandle>(null);

    const formRefs = [contactFormViewer];

    const isFormValid = async () => {
      const areFormsValidArr = await Promise.all(
        formRefs.filter((ref) => !!ref.current).map((ref) => ref.current!.validate())
      );

      return !areFormsValidArr.includes(false);
    };

    const initForm = () => {
      setContactFormValues(getContactFormInitialState(checkoutSettings, checkout.billingInfo?.contact));
    };

    const getContactDetailsFormValues = () => {
      return getContactDetailsFromContactFormValues(contactFormValues, checkoutSettings, checkout.billingInfo?.contact);
    };

    return (
      <BillingDataContext.Provider
        value={{
          contactFormValues,
          setContactFormValues,
          contactFormViewer,
          isFormValid,
          initForm,
          getContactDetailsFormValues,
          cashierMandatoryFields,
          setCashierMandatoryFields,
        }}>
        <Component {...props} />
      </BillingDataContext.Provider>
    );
  };
}

export function useBillingData() {
  const {
    contactFormValues,
    setContactFormValues,
    contactFormViewer,
    isFormValid,
    initForm,
    getContactDetailsFormValues,
    cashierMandatoryFields,
    setCashierMandatoryFields,
  } = useContext(BillingDataContext);

  return {
    contactFormValues,
    setContactFormValues,
    contactFormViewer,
    isFormValid,
    initForm,
    getContactDetailsFormValues,
    cashierMandatoryFields,
    setCashierMandatoryFields,
  };
}
