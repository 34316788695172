import {FormValues} from '@wix/form-viewer';
import {FullAddressContactDetails} from '@wix/ambassador-ecom-v1-checkout/types';
import {CheckoutSettingsModel} from '../models/checkoutSettings/CheckoutSettings.model';
import {ContactModel} from '../models/Contact.model';

export const getContactFormInitialState = (
  checkoutSettings: CheckoutSettingsModel,
  contact?: ContactModel
): FormValues => ({
  first_name: contact?.firstName ?? '',
  last_name: contact?.lastName ?? '',
  ...(checkoutSettings.phone.show ? {phone: contact?.phone ?? ''} : {}),
  ...(checkoutSettings.companyName.show ? {company_name: contact?.company ?? ''} : {}),
});

export const getContactDetailsFromContactFormValues = (
  contactFormValues: FormValues,
  checkoutSettings: CheckoutSettingsModel,
  contact?: ContactModel
): FullAddressContactDetails => ({
  firstName: contactFormValues.first_name as string,
  lastName: contactFormValues.last_name as string,
  ...(checkoutSettings.phone.show && contactFormValues.phone ? {phone: contactFormValues.phone as string} : {}),
  ...(checkoutSettings.companyName.show && contactFormValues.company_name
    ? {company: contactFormValues.company_name as string}
    : {}),
  ...(contact?.vatId ? /* istanbul ignore next */ {vatId: contact.vatId} : {}),
});
