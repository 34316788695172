import React, {useState} from 'react';
import {FormError} from '@wix/form-viewer/widget';
import {ContactForm} from '../../../../Form/ContactForm/ContactForm';
import {Text} from 'wix-ui-tpa';
import {classes} from '../PaymentStep.st.css';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {FormLoader} from '../../../../Form/FormLoader/FormLoader';
import {BillingAddressDropdown} from '../BillingAddressDropdown/BillingAddressDropdown';
import {useBillingData} from '../WithBillingData/WithBillingData';

export enum BillingDetailsDataHook {
  root = 'BillingDetails.root',
  title = 'BillingDetails.title',
  contactForm = 'BillingDetails.contactForm',
  addressForm = 'BillingDetails.addressForm',
}

export const BillingDetails = () => {
  const {
    formsStore: {areFormsLoaded},
    navigationStore: {isMember},
  } = useControllerProps();

  const {setContactFormValues, contactFormValues, contactFormViewer, cashierMandatoryFields} = useBillingData();

  const [contactFormErrors, setContactFormErrors] = useState<FormError[]>([]);
  const localeKeys = useLocaleKeys();

  return (
    <div data-hook={BillingDetailsDataHook.root} className={classes.root}>
      <Text data-hook={BillingDetailsDataHook.title}>{localeKeys.checkout.billing_information.title()}</Text>
      {isMember && <BillingAddressDropdown />}
      {!areFormsLoaded && /* istanbul ignore next: test forms */ <FormLoader />}
      {areFormsLoaded && (
        <div data-hook={BillingDetailsDataHook.contactForm} className={classes.billingForm}>
          <ContactForm
            formRef={contactFormViewer}
            formValues={contactFormValues}
            formErrors={contactFormErrors}
            setFormValues={setContactFormValues}
            setFormErrors={setContactFormErrors}
            overridePhone={cashierMandatoryFields.phone}
          />
        </div>
      )}
    </div>
  );
};
