import React from 'react';
import {MemberInfoBox} from '../MemberInfoBox/MemberInfoBox';
import {ExpressCheckoutButtons} from '@wix/ecom-platform-express-checkout-buttons';
import {useControllerProps} from '../Widget/ControllerContext';
import {StepsManager} from '../CheckoutSteps/StepsManager/StepsManager';
import {CustomerDetailsStep} from '../CheckoutSteps/Steps/CustomerDetailsStep/CustomerDetailsStep';
import {PaymentStep} from '../CheckoutSteps/Steps/PaymentStep/PaymentStep';
import {PlaceOrderStep} from '../CheckoutSteps/Steps/PlaceOrderStep/PlaceOrderStep';
import {StepId} from '../CheckoutSteps/types';

export enum FullFlowDataHook {
  root = 'FullFlow.root',
  title = 'FullFlow.title',
  expressCheckout = 'FullFlow.expressCheckout',
}

export const FullFlow = () => {
  const {
    checkoutStore: {
      ambassadorCheckout,
      checkout: {payNowTotalAfterGiftCard},
    },
    stepsManagerStore: {
      updateStepOnStage,
      sendEditStepClickedBIEvent,
      shouldDisplayExpressCheckout,
      updateActiveStepId,
      activeStep,
      sendStageExpandedBIEvent,
    },
  } = useControllerProps();

  return (
    <div data-hook={FullFlowDataHook.root}>
      {shouldDisplayExpressCheckout && (
        <div data-hook={FullFlowDataHook.expressCheckout}>
          <ExpressCheckoutButtons checkout={ambassadorCheckout} layout={'horizontal'} />
        </div>
      )}
      <MemberInfoBox />
      <StepsManager
        activeStep={activeStep}
        openStep={(index, stepId) => {
          updateStepOnStage(index, stepId);
          sendEditStepClickedBIEvent(stepId);
        }}
        onStepOpened={(stepId: StepId) => {
          sendStageExpandedBIEvent(stepId);
          updateActiveStepId(stepId);
        }}>
        <CustomerDetailsStep />
        {payNowTotalAfterGiftCard.amount ? <PaymentStep /> : null}
        <PlaceOrderStep />
      </StepsManager>
    </div>
  );
};
