import React, {useState} from 'react';
import {useControllerProps} from '../Widget/ControllerContext';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {DeliveryMethodGroup} from './DeliveryMethodGroup';
import {DeliveryMethod} from './DeliveryMethod';
import {ScheduledDeliverySelector} from './ScheduledDeliverySelector';
import {ShippingOptionModel} from '../../../domain/models/ShippingOption.model';
import {PickupInfoBox} from '../PickupInfoBox/PickupInfoBox';

export enum DeliveryMethodPickerDataHook {
  sectionTitle = 'DeliveryMethodPicker.sectionTitle',
  shippingRadioGroup = 'DeliveryMethodPicker.shippingRadioGroup',
  shippingOption = 'DeliveryMethodPicker.shippingOption',
  shippingOptionPrice = 'DeliveryMethodPicker.shippingOptionPrice',
  pickupBox = 'DeliveryMethodPicker.pickupBox',
}

export const DeliveryMethodPicker = ({dataHook}: {dataHook: string}) => {
  const {
    deliveryMethodStore,
    checkoutStore: {checkout, isPickupFlow},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();
  const {setShippingOption, isUpdatingDeliveryMethod} = deliveryMethodStore;
  const shouldShowShippingSection = !!checkout.shippingOptions?.length && !isPickupFlow;
  const shouldShowPickupSection = !!checkout.pickupOptions?.length;
  const shouldShowSectionHeaders = shouldShowShippingSection && shouldShowPickupSection;
  const [selectedShippingOptionId, setSelectedShippingOptionId] = useState(checkout.selectedShippingOption?.code);

  const getInitiallySelectedRadioButton = () =>
    [...checkout.shippingOptions, ...checkout.pickupOptions].find((deliveryOption) =>
      deliveryOption.scheduledDeliveryOptions?.some((option) => option.code === checkout.selectedShippingOption?.code)
    )?.code ?? checkout.selectedShippingOption?.code;

  const [selectedRadioButtonId, setSelectedRadioButtonId] = useState(getInitiallySelectedRadioButton);

  const onOptionSelected = (optionId: string): void => {
    setSelectedShippingOptionId(optionId);
    setSelectedRadioButtonId(optionId);
    void setShippingOption(optionId);
  };

  const onScheduledDeliverySelected = (optionId: string): void => {
    setSelectedShippingOptionId(optionId);
    void setShippingOption(optionId);
  };

  const getShippingSubtext = (option: ShippingOptionModel) => {
    return option.scheduledDeliveryOptions ? undefined : option.deliveryTime;
  };

  const getPickupSubtext = ({pickupInfo}: ShippingOptionModel) => {
    return localeKeys.checkout.delivery_method.pickup_address({
      addressLine: pickupInfo?.address?.addressLine,
      city: pickupInfo?.address?.city,
      country: pickupInfo?.address?.countryFullname,
      subdivision: pickupInfo?.address?.subdivisionFullname,
      zipCode: pickupInfo?.address?.postalCode,
    });
  };

  return (
    <div data-hook={dataHook}>
      {shouldShowShippingSection && (
        <DeliveryMethodGroup
          title={localeKeys.checkout.deliveryMethod.shipping.label()}
          showHeader={shouldShowSectionHeaders}
          selectedId={selectedRadioButtonId}
          onSelected={(selectedId) => onOptionSelected(selectedId)}>
          {checkout.shippingOptions.map((option) => (
            <DeliveryMethod
              key={option.code}
              option={option}
              disabled={isUpdatingDeliveryMethod}
              selectedId={selectedRadioButtonId}
              subtext={getShippingSubtext(option)}
              selectedDetailSection={() => {
                if (!option.scheduledDeliveryOptions) {
                  return null;
                }
                return (
                  <ScheduledDeliverySelector
                    disabled={isUpdatingDeliveryMethod}
                    selectedId={selectedShippingOptionId}
                    scheduledDeliveryOptions={option.scheduledDeliveryOptions}
                    onSelect={(optionId) => onScheduledDeliverySelected(optionId)}
                  />
                );
              }}
            />
          ))}
        </DeliveryMethodGroup>
      )}

      {shouldShowPickupSection && (
        <DeliveryMethodGroup
          title={localeKeys.checkout.deliveryMethod.pickup.label()}
          showHeader={shouldShowSectionHeaders}
          selectedId={selectedRadioButtonId}
          onSelected={(selectedId) => onOptionSelected(selectedId)}>
          {checkout.pickupOptions.map((option) => (
            <DeliveryMethod
              key={option.code}
              option={option}
              disabled={isUpdatingDeliveryMethod}
              selectedId={selectedRadioButtonId}
              subtext={getPickupSubtext(option)}
              selectedDetailSection={() => <PickupInfoBox pickupInfo={option.pickupInfo} />}
            />
          ))}
        </DeliveryMethodGroup>
      )}
    </div>
  );
};
