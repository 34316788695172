import {StepState} from '../../StepsManager/StepState';
import {classes} from './PaymentStep.st.css';
import {PaymentsWidget} from '@wix/cashier-payments-widget/lazy';
import {cashierMandatoryFieldPlaceholder} from '../../../../../domain/utils/cashier.utils';
import React, {useEffect} from 'react';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {usePaymentsApi} from '../../../WithPaymentsApi/WithPaymentsApi';
import {StartPayment} from '../../../../../domain/utils/analytics.utils';

export const PaymentWidgetWrapper = ({stepState}: {stepState: StepState}) => {
  const {
    paymentStore: {cashierWidgetProps},
    navigationStore: {trackEvent},
  } = useControllerProps();
  const {setPaymentsApi, paymentsApi, setActivePaymentId} = usePaymentsApi();

  useEffect(
    () => {
      if (stepState === StepState.OPEN) {
        void paymentsApi?.expand();
        trackEvent(...StartPayment);
      } else if (stepState === StepState.COLLAPSED) {
        void paymentsApi?.collapse();
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [stepState]
  );

  // istanbul ignore if
  if (!cashierWidgetProps) {
    return null;
  }

  const isWidgetHidden = stepState === StepState.EMPTY;

  return (
    <div className={isWidgetHidden ? classes.paymentHidden : undefined}>
      <PaymentsWidget
        {...cashierWidgetProps}
        isWidgetVisible={!isWidgetHidden}
        isFullWidth={true}
        mandatoryFields={cashierMandatoryFieldPlaceholder}
        onApiInit={setPaymentsApi}
        paymentMethodChanged={setActivePaymentId}
        paymentResult={/* istanbul ignore next */ () => null}
      />
    </div>
  );
};
