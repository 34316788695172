import {ICashierPaymentsApi, PaymentMethod} from '@wix/cashier-payments-widget';
import {CashierMandatoryField, CashierMandatoryFieldsOverrides} from '../../types/app.types';

export const cashierMandatoryFieldPlaceholder = {
  email: '-',
  firstName: '-',
  lastName: '-',
  street: '-',
  houseNumber: '-',
  address: '-',
  city: '-',
  countryCode: '-',
  state: '-',
  zipCode: '-',
  phone: '-',
};

export const cashierWidgetEwalletPaymentMethod: string[] = [PaymentMethod.PayPal, PaymentMethod.GooglePay];

export const updateMandatoryFields = async (
  paymentsApi: ICashierPaymentsApi | undefined,
  setCashierMandatoryFields: React.Dispatch<React.SetStateAction<CashierMandatoryFieldsOverrides>>,
  country?: string
): Promise<void> => {
  //@ts-expect-error cashier api
  const mandatoryFields: string[] = (await paymentsApi?.fetchMandatoryFields(country)) ?? [];
  const cashierMandatoryFieldsOverrides: CashierMandatoryFieldsOverrides = {};

  const formattedCashierMandatoryFields =
    mandatoryFields
      ?.map((field) => {
        return CashierMandatoryField[field as CashierMandatoryField];
      })
      .filter((field) => Boolean(field)) ?? /* istanbul ignore next */ [];

  formattedCashierMandatoryFields.forEach((field) => {
    cashierMandatoryFieldsOverrides[field] = true;
  });

  setCashierMandatoryFields(cashierMandatoryFieldsOverrides);
};
