import {Dropdown, DropdownTheme} from 'wix-ui-tpa';
import React, {useEffect} from 'react';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../../../Widget/ControllerContext';

export enum BillingAddressDropdownDataHook {
  root = 'BillingAddressDropdown.root',
  dropdown = 'BillingAddressDropdown.dropdown',
}

export const BillingAddressDropdown = () => {
  const {
    addressStore: {addressesInfo, fetchAddresses},
  } = useControllerProps();

  useEffect(
    () => {
      void fetchAddresses();
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ []
  );
  const localeKeys = useLocaleKeys();

  const dropdownOptions = addressesInfo?.addresses?.map((address) => {
    return {
      id: address.id,
      value: address.addressLine1,
      subtitle: `${address.fullName.firstName} ${address.fullName.lastName}`,
      isSelectable: true,
    };
  });
  return (
    <div data-hook={BillingAddressDropdownDataHook.root}>
      <Dropdown
        data-hook={BillingAddressDropdownDataHook.dropdown}
        label={localeKeys.chooseAddressModal.billing_address_title()}
        initialSelectedId={addressesInfo.defaultAddressId}
        upgrade={true}
        theme={DropdownTheme.Box}
        options={dropdownOptions}
      />
    </div>
  );
};
